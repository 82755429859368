import React, {useEffect} from 'react';
import './Link.css';
import LinkImg from './../img/back.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
const Link = () => {
  useEffect(()=>{
    AOS.init({
      duration:1300,
    });
  },[]);
  return (
    <>
      <div className="link">
        <div className="prime_link"  data-aos="fade-up">
            <h3>Join With Us</h3>
            <p>Joining our institute is your gateway to a world of opportunities. Whether you're a student seeking academic excellence, an individual aspiring to master English language skills for exams like IELTS and PTE, or a future educator looking to enhance your teaching abilities, we welcome you with open arms. Our institute is where dreams are nurtured, skills are honed, and success is achieved. Come, be a part of our vibrant learning community and embark on a transformative educational journey with us. </p>
        </div>

        <div className="link_img"  data-aos="fade-up">
            <img src={LinkImg} alt="" />
        </div>
      </div>
    </>
  );
};

export default Link;
