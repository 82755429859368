import React from 'react';
import './App.css';
import Home from './Home/Home';

const App = () => {
  return (
    <>
     <Home/>
    </>
  );
};

export default App;
