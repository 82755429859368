import courseimg from "./../img/img.png";
import courseimg1 from "./../img/img1.png";
import courseimg2 from "./../img/img2.png";
import courseimg3 from "./../img/img3.png";
import courseimg4 from "./../img/img4.png";
import courseimg5 from "./../img/img5.png";


const CourseData =[
    {
        id:1,
        title:"Tuition Classes",
        img:courseimg
,    },

    {
        id:2,
        title:"Basic English Classes",
        img:courseimg1,
    },

    {
        id:3,
        title:"IELTS",
        img:courseimg2,
    },

    {
        id:4,
        title:"PTE",
        img:courseimg3,
    },

    {
        id:5,
        title:"Guitar Classes",
        img:courseimg4,
    },
    {
        id:6,
        title:"Teacher's Training",
        img:courseimg5,
    },
    
  
];

export default CourseData;
